<template>
  <FrontHeader />
  <loading
    v-model:active="this.$store.state.isLoading"
    :can-cancel="false"
    :widh="100"
    :height="100"
    :is-full-page="true"
    :opacity="0.7"
    :color="loadingColor"
    :background-color="loadingBGColor"
  ></loading>
  <router-view></router-view>
</template>
<script>
import FrontHeader from "@/components/FrontHeader.vue";
import Loading from 'vue-loading-overlay'
export default {
  components: { FrontHeader, Loading },
  name: "App",
  data(){
    return {
      loadingColor:"#0dcaf0",
      loadingBGColor:"#000"
    }
  }
};
</script>

<style lang="scss">
@import "~bootstrap/scss/bootstrap";
@import "./assets/css/front/all.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  background: #3b3b3b;
  min-height:100vh
}
</style>

<template>
  <div class="d-flex justify-content-center">
    <div class="BtnView" @click="$emit('triggerClick')"><div class="">下一步</div></div>
  </div>
</template>
<script>
export default {
  name: "BtnView",
};
</script>

<style scoped lang="scss">
.BtnView {
  height: 42px;
  width: 134px;
  left: 20px;
  top: 20px;
  border-radius: 30px;
  padding: 0px 3px 0px 3px;
  background: linear-gradient(90.38deg, #029DBF 0.33%, #00B3C4 105.25%);
  text-align: center;
  line-height: 200%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.BtnView div{text-decoration:none;}
</style>

<template>
  <Frame />
</template>
<script>
import Frame from "@/components/Frame.vue";
export default {
  name: "FrontHeader",
  components: { Frame },
};
</script>

<style scoped lang="scss"></style>

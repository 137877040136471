import { createRouter, createWebHistory } from "vue-router";
import Home from "@/views/HomeView.vue";
import store from '@/store';

const routes = [
  {
    path: "/session-start/:chargePoint?",
    name: "Home",
    component: Home,
  },
  {
    path: "/pay",
    name: "Pay",
    component: () => import("../views/PayView.vue"),
    meta: { requiresChargingPointId: true },
  },
  {
    path: "/pay_failure",
    name: "PayFailure",
    component: () => import("../views/PayFailureView.vue"),
  },
  {
    path: "/battery_progress/:sessionId?",
    name: "BatteryProgress",
    component: () => import("../views/BatteryProgressView.vue"),
  },
  {
    path: "/battery_confirm",
    name: "BatteryConfirm",
    component: () => import("../views/BatteryConfirmView.vue"),
    meta: { requiresChargingPointId: true },
  },
  {
    path: "/battery_access/:sessionId?",
    name: "BatteryAccess",
    component: () => import("../views/BatteryAccessViews.vue"),
  },
  {
    path: "/charging_connecting",
    name: "ChargingConnecting",
    component: () => import("../views/ChargingConnectingViews.vue"),
  },
  {
    path: "/charging_progress/:sessionId?",
    name: "ChargingProgress",
    component: () => import("../views/ChargingProgressViews.vue"),
  },
  {
    path: "/charging_ending",
    name: "ChargingEnding",
    component: () => import("../views/ChargingEndingViews.vue"),
  },
  {
    path: "/charging_fail",
    name: "ChargingFail",
    component: () => import("../views/ChargingFailViews.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  console.log(to)
  console.log(from)
  console.log(store.state.chargingPointId)
  if (to.meta.requiresChargingPointId && !store.state.chargingPointId) {
    next({ name: "Home" });
  } else {
    next();
  }
})
export default router;
